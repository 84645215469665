import React, { useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import Editor, { EditorPlugin } from 'draft-js-plugins-editor';
import { getInlineToolbarComp, myDraftStyle, myActions } from './Toolbar/plugins';
import { mapSelectionToGeneric, getRangeFromSelection } from './Utils';
import { getInlineToolbarComps } from './Toolbar/components';
import {
  ContentBlock,
  DraftHandleValue,
  EditorState,
  SelectionState,
  CharacterMetadata,
  getDefaultKeyBinding,
  KeyBindingUtil,
} from 'draft-js';
import TranscriptRow from './TranscriptRow';
import BoxFindOrReplace from './BoxFindOrReplace';
import { findWithRegex, generateDecorator } from './BoxFindOrReplace/decorator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark } from '@fortawesome/free-solid-svg-icons';
const { hasCommandModifier } = KeyBindingUtil;
const inlineToolbars = [
  {
    style: myDraftStyle.HIGHLIGHT,
    action: myActions.REMOVE,
    visibleWhenInactive: false,
  },
  {
    style: myDraftStyle.HIGHLIGHT,
    action: myActions.INSERT,
    visibleWhenInactive: true,
  },
  {
    style: myDraftStyle.HIGHLIGHT,
    action: myActions.APPEND,
    visibleWhenInactive: true,
  },
];

type TranscriptProps = {
  //TODO: fix these types
  handleInsertClick: (media: ImportedMedia, range: SegmentRange, selection: GenericSelection) => void;
  handleAppendClick: (media: ImportedMedia, range: SegmentRange, selection: GenericSelection) => void;
  handleRemoveClick: (media: ImportedMedia, editorState: EditorState, range: SegmentRange, selection: GenericSelection) => void;
  editorState: EditorState;
  setEditorState: (editorState: EditorState) => any;
  onChangeTimePlaying: (timeInSeconds: number | null) => void;
  onChangeCursor: () => void;
  readOnly?: boolean;
  mediaTimecode?: string;
  media: ImportedMedia;
  identifier: string;
};

function Transcript({
  handleInsertClick,
  handleAppendClick,
  handleRemoveClick,
  editorState,
  setEditorState,
  onChangeTimePlaying,
  onChangeCursor,
  readOnly,
  mediaTimecode,
  media,
  identifier,
}: TranscriptProps) {
  const inputSearchRef = useRef<HTMLInputElement | null>(null);
  const editorRef = useRef<any>(null);
  const [isLockEditor, setIsLockEditor] = useState(false);
  const [textSearch, setTextSearch] = useState<string>('');
  const [selectionsToReplace, setSelectionsToReplace] = useState([]);
  const [currentSelectionToReplace, setCurrentSelectionToReplace] = useState<number | null>(null);
  const [blocksRelevantFilter, setBlocksRelevantFilter] = useState<string[]>([]);
  const [blocksRelevantSearch, setBlocksRelevantSearch] = useState<string[]>([]);
  const [currentFilters, setCurrentFilters] = useState<string[]>([]);
  const [{ plugins, InlineToolbar }] = useState(getInlineToolbarComp())

  useEffect(() => {
    // @ts-ignore 
    function handleKeydown (e) {
      if (e.keyCode === 70 /* `f` key */ && hasCommandModifier(e)) {
        e.preventDefault();
        if(inputSearchRef.current) inputSearchRef.current.focus();
      }
    }

    // @ts-ignore
    document.body.addEventListener('keydown', handleKeydown, false);

    return () => {
      // @ts-ignore
      document.body.removeEventListener('keydown', handleKeydown);
    }
  }, [inputSearchRef]);

  useEffect(() => {
    focus()
  }, [media])

  const handleChange: (newState: EditorState) => void = newState => {
    // we may start doing things here again
    setEditorState(newState);
    onChangeCursor();
  };

  const handleInlineStyle = (style: any, action: string) => {
    const selectionRange = getRangeFromSelection(editorState, undefined);
    const selection = mapSelectionToGeneric(editorState.getSelection(), editorState);
    if (!selectionRange) {
      return;
    }
    if (action === 'INSERT') {
      handleInsertClick(media, selectionRange, selection);
    } else if (action === 'APPEND') {
      handleAppendClick(media, selectionRange, selection);
    } else if (action === 'REMOVE') {
      handleRemoveClick(media, editorState, selectionRange, selection);
    }
  };

  const ignoreInput: (chars: string, editorState: EditorState, eventTimeStamp: number) => DraftHandleValue = () => {
    return 'handled';
  };
  const ignorePastedText: (text: string, html: string, editorState: EditorState) => DraftHandleValue = () => {
    return 'handled';
  };

  // custom key binding for undo as it doesn't seem to be picked up otherwise
  const myKeyBindingFn = (e: any): string | null => {
  if (e.keyCode === 90 /* `Z` key */ && hasCommandModifier(e)) {
    return null;
  }

  if (e.keyCode === 87 /* `w` key */) {
    return "insert";
  }

  if (e.keyCode === 69 /* `e` key */) {
    return "append";
  }
  return getDefaultKeyBinding(e);
}
  const handleKeyCommand = (command: string): DraftHandleValue => {
    if (command === 'undo' || command === "redo" || command === "delete" || command === "backspace" || command === "split-block") {
      //ignore undo for the time being
      return 'handled';
    }
    if (command === 'insert') {
      handleInlineStyle(null, "INSERT")
      return 'handled';
    }
    if (command === 'append') {
      handleInlineStyle(null, "APPEND")
      return 'handled';
    }
    return 'not-handled';
  }

  function blockRendererFn(contentBlock: ContentBlock) {
    const type = contentBlock.getType();
    if (type === 'TranscriptRow') {
      return {
        component: TranscriptRow,
        props: {
          mediaTimecode,
          // onUpdateBlockData: this.onUpdateBlockData,
          // updateAllMatchingAutoSpeaker: this.updateAllMatchingAutoSpeaker,
          // onChangeps4State: this.onChange,
          // onSaveTranscript: (callback) =>
          //   this.saveTranscript(this.props.currentMedia.id, callback),
          // onPopoverInteraction: this.onPopoverInteraction,
          // onUpdateMediaSpeaker: this.props.onUpdateMediaSpeaker,
          // onAddMediaSpeaker: this.props.onAddMediaSpeaker,
          // onBookmarkChange: this.bookmarkChange,
          // isTextRightToLeft: this.state.isTextRightToLeft,
          // isSubtitled: this.props.currentMedia.status == "subtitled",
          // isReadOnly:
          //   this.props.isPublicPageView ||
          //   this.props.isLocked ||
          //   this.props.isReadOnly,
          currentMedia: media,
          blocksRelevantFilter: _.uniq([...blocksRelevantFilter, ...blocksRelevantSearch]),
          onChangeTimePlaying: onChangeTimePlaying,
          lockEditor: lockEditor,
          unlockEditor: unlockEditor,
          identifier,
        },
      };
    }
  }

  function lockEditor(callback: () => void | undefined) {
    setIsLockEditor(true);
    if (typeof callback === 'function') callback();
  }

  function unlockEditor(callback: () => void | undefined) {
    setIsLockEditor(false);
    if (typeof callback === 'function') callback();
  }

  function onRemoveSearch() {
    setTextSearch('');
    setSelectionsToReplace([]);
    setCurrentSelectionToReplace(null);
    setBlocksRelevantSearch([]);
    setEditorState(EditorState.set(editorState, { decorator: generateDecorator('', null) }));
  }

  function onSearch(selectionToReplace?: number) {
    if (!textSearch) {
      onRemoveSearch();
      return;
    }

    const regex = new RegExp(textSearch ?? '', 'gi');
    const newSelectionsToReplace: any[] = [];
    const blockMap = editorState.getCurrentContent().getBlockMap();
    const newBlocksRelevantFilter: string[] = [];

    blockMap.map(contentBlock =>
      findWithRegex(regex, contentBlock, (start: number, end: number) => {
        const blockKey = contentBlock?.getKey();
        const blockSelection = SelectionState.createEmpty(blockKey ?? '').merge({
          anchorOffset: start,
          focusOffset: end,
        });

        newBlocksRelevantFilter.push(blockKey!);
        newSelectionsToReplace.push(blockSelection);
      })
    );

    let currentSelectionToReplace = newSelectionsToReplace.length ? 0 : null;
    currentSelectionToReplace = selectionToReplace ?? currentSelectionToReplace;
    setCurrentSelectionToReplace(currentSelectionToReplace);
    setBlocksRelevantSearch(newBlocksRelevantFilter);
    setEditorState(
      EditorState.set(editorState, {
        decorator: generateDecorator(textSearch, selectionsToReplace[currentSelectionToReplace ?? -1]),
      })
    );
  }

  function handleFilter(filterStyles: Array<string>) {
    if (filterStyles.length === 0 && blocksRelevantFilter.length === 0) return;
    filterStyles = filterStyles.filter(item => !currentFilters.includes(item));

    const contentState = editorState.getCurrentContent();
    const blocks = contentState.getBlockMap().toArray();
    const regex = /\FILTER_BLOCK_DATA_.*/;
    const newBlocksRelevantFilter: string[] = [];

    filterStyles.map(filter => {
      if (regex.test(filter)) {
        newBlocksRelevantFilter.push(...handleFilterBlockData(blocks, filter));
      } else {
        newBlocksRelevantFilter.push(...handleFilterInlineStyle(blocks, filter));
      }
    });

    if (JSON.stringify(blocksRelevantFilter) !== JSON.stringify(newBlocksRelevantFilter)) {
      setEditorState(EditorState.forceSelection(editorState, editorState.getSelection()));
      setCurrentFilters(filterStyles);
      setBlocksRelevantFilter(newBlocksRelevantFilter);
    }
  }

  function handleFilterInlineStyle(blocks: ContentBlock[], filterStyle = myDraftStyle.HIGHLIGHT) {
    const blocksRelevantToStyle: string[] = [];

    blocks.map((block: ContentBlock, index) => {
      const characterList = block.getCharacterList();
      characterList.map(char => {
        const styles = char?.getStyle();
        if (styles?.size && styles.has(filterStyle)) {
          blocksRelevantToStyle.push(block.getKey());
        }
      });
    });

    return [...blocksRelevantToStyle];
  }

  function handleFilterBlockData(blocks: ContentBlock[], filterStyle: string = 'FILTER_BLOCK_DATA_annotation') {
    if (!filterStyle) return [];

    const blocksRelevantToData: string[] = [];
    const regex = /FILTER_BLOCK_DATA_(?:.*)/;
    const dataBlockProperty = filterStyle.match(regex)![1];

    blocks.map((block, index) => {
      const { data } = block.toJS();
      if (data[dataBlockProperty] && data.blockData !== null) {
        blocksRelevantToData.push(block.getKey());
      }
    });

    return [...blocksRelevantToData];
  }

  function focus() {
    editorRef.current.focus();
  }

  return (
    <React.Fragment>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ flex: 1 }}>
          <BoxFindOrReplace
            ref={inputSearchRef}
            active={true}
            textSearch={textSearch}
            onSearch={onSearch}
            onRemoveSearch={onRemoveSearch}
            onChange={e => setTextSearch(e.target.value)}
          />
        </div>
        <div style={{ width: 16, height: 16, position: 'relative', right: 18, cursor: 'pointer' }}>
          <span onClick={() => handleFilter([myDraftStyle.HIGHLIGHT])}>
            {(currentFilters.includes(myDraftStyle.HIGHLIGHT) && (
              <span className="ss-qoute">
                <svg fill="#C8247F" x="0px" y="0px" viewBox="0 0 100 125">
                  <path d="M36.667,30h-20c0-7.363,5.973-13.333,13.333-13.333V10c-11.042,0-20,8.952-20,20v26.667c0,3.665,3.001,6.666,6.667,6.666h20  c3.665,0,6.666-3.001,6.666-6.666v-20C43.333,33.001,40.332,30,36.667,30z" />
                  <path d="M70,36.667v6.666c7.36,0,13.333,5.971,13.333,13.334h-20c-3.665,0-6.666,3.001-6.666,6.666v20  c0,3.666,3.001,6.667,6.666,6.667h20C86.999,90,90,86.999,90,83.333V56.667C90,45.618,81.042,36.667,70,36.667z" />
                </svg>
              </span>
            )) || (
              <span className="ss-qoute-outline">
                <svg fill="#C8247F" x="0px" y="0px" viewBox="0 0 100 125">
                  <path d="M36.667,30h-20c0-7.363,5.973-13.332,13.333-13.332V10c-11.042,0-20,8.954-20,20v26.666c0,3.666,3.001,6.668,6.667,6.668h20  c3.665,0,6.666-3.002,6.666-6.668v-20C43.333,33,40.332,30,36.667,30z M36.667,56.666h-20v-20h20V56.666z" />
                  <path d="M70,36.667v6.668c7.36,0,13.333,5.968,13.333,13.331h-20c-3.665,0-6.666,3.002-6.666,6.668v20  c0,3.664,3.001,6.666,6.666,6.666h20C86.999,90,90,86.998,90,83.334V56.666C90,45.62,81.042,36.667,70,36.667z M83.333,83.334h-20  v-20h20V83.334z" />
                </svg>
              </span>
            )}
          </span>
        </div>
      </div>
      <div onClick={focus} className="transcript-container">
        <Editor
          ref={editorRef}
          editorState={editorState}
          // readOnly={isLockEditor}
          onChange={handleChange}
          blockRendererFn={blockRendererFn}
          handleBeforeInput={ignoreInput}
          handlePastedText={ignorePastedText}
          handleKeyCommand={handleKeyCommand}
          keyBindingFn={myKeyBindingFn}
          plugins={plugins as EditorPlugin[]}
        />
        {!readOnly && (
          <InlineToolbar>
            {externalProps =>
              inlineToolbars.map(key =>
                getInlineToolbarComps({
                  style: key.style,
                  action: key.action,
                  visibleWhenInactive: key.visibleWhenInactive,
                  props: externalProps,
                  onClick: () => handleInlineStyle(myDraftStyle[key.style], key.action),
                })
              )
            }
          </InlineToolbar>
        )}
      </div>
    </React.Fragment>
  );
}

export default Transcript;
