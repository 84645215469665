import React, { useState, useRef, useEffect, useMemo, MouseEventHandler, useContext } from 'react';
import { progressBarStore } from '../../context/ProgressBarContext';
import { AMEND_REACTION_TO_SEGMENT } from '../../queries';
import { useMutation } from '@apollo/client';
import { Row, Col, Card, CardText, CardBody, CardTitle, Button, Popover } from 'reactstrap';
import * as Popper from 'popper.js';
import moment, { Duration } from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faHistory, faSpinner, faGripVertical } from '@fortawesome/free-solid-svg-icons';
import Thread from '../popover/Thread';
import ThreadPopover from '../popover/ThreadPopover';
import { useTextInputSettable } from '../../common/custom-hook';
import { ChapterItemSVG } from '../svg';

enum ReactionEnum {
  LIKE = 'LIKE',
  DISLIKE = 'DISLIKE',
  LOVE = 'LOVE',
  LAUGH = 'LAUGH',
}

momentDurationFormatSetup(moment);

const shorten = (text: string) => {
  const splitText = text.split(' ');
  if (splitText.length > 50) {
    return splitText.splice(0, 20).join(' ') + ' [...] ' + splitText.splice(-20, 20).join(' ');
  }
  return text;
};

const popoverDurationModifiers = () => {
  return {
    offset: {
      offset: '0, 25',
    },
    preventOverflow: {
      boundariesElement: 'viewport',
    },
  };
};

type SegmentCardProps = {
  canView: boolean;
  segment: Segment;
  url: string;
  startTimecodeInSeconds: number;
  isCurrent: boolean;
  index: number;
  readOnly?: boolean;
  // isLoadMedia: boolean;
  isTimeOut: boolean;
  handleRemoveClicked: () => void;
  handleSegmentClicked: (segment: Segment) => void;
  handleVideoThumbnailClicked: () => void;
  // handleMediaCanPlay: (index: number, isTimeOut: boolean) => void;
  handleEditBlankSegment: (index: number, segment: Segment) => void;
};

const TIME_TO_RETRY_ATTEMP_RELOAD_MEDIA = 30000;

const SegmentCard = React.forwardRef(
  (
    {
      index,
      segment,
      url,
      startTimecodeInSeconds,
      isCurrent,
      readOnly,
      // isLoadMedia,
      isTimeOut,
      handleRemoveClicked,
      handleSegmentClicked,
      handleVideoThumbnailClicked,
      // handleMediaCanPlay,
      handleEditBlankSegment,
    }: SegmentCardProps,
    ref: any,
  ) => {
    const { pushProgress, completeProgress } = useContext(progressBarStore);

    const [amendReaction, { loading: loadingAmendReaction }] = useMutation<{
      segmentFingerprint: string;
      type: number;
      remove: boolean;
    }>(AMEND_REACTION_TO_SEGMENT, {
      onCompleted: () => {
        completeProgress('amendReaction');
      },
    });

    const [isLoading, setIsLoading] = useState(true);
    const [isOpenThread, setIsOpenThread] = useState(false);
    const mediaRef = useRef<HTMLVideoElement>(null);
    const [urlMedia, setUrlMedia] = useState(url);
    const [isOpenPopoverDuration, setIsOpenPopoverDuration] = useState(false);
    const inputBlankSegmentText = useTextInputSettable(segment.text);
    const inputBlankSegmentDuration = useTextInputSettable(segment.end, validateDuration);

    function validateDuration(event: any) {
      const value = event.target.value;
      return isNumberOrEmpty(value) && lessOrEqualOneHour(+value);
    }

    function isNumberOrEmpty(value: string) {
      return /\d|^$/gi.test(value) && lessOrEqualOneHour(+value);
    }

    function lessOrEqualOneHour(num: number) {
      return num <= 3600;
    }

    const formatTime = (meida: ImportedMedia, timeString: string, startTimecodeInSeconds: string) => {
      const fps = meida.fps;
      let time = parseFloat(timeString);

      // ?: Should we need to find another solution?
      // temporarily fix wrong display which only happen when media fps is 23.976
      // multiply by magic number is 0.999
      if (fps === 23.976) {
        const magicNumber = 0.999;
        time *= magicNumber;
      }

      let duration = moment.duration(time + parseFloat(startTimecodeInSeconds), 'seconds');

      let formatted = duration.format('hh:mm:ss');
      if (duration.asSeconds() < 60) {
        formatted = `:${formatted}`;
      }
      return formatted;
    };

    const diffTime = (startTime: number, endTime: number) => {
      const duration = moment.duration(String(endTime - startTime), 'seconds');
      return duration.format('h[h]m[m]s[s]');
    };

    // useEffect(() => {
    //   const media = mediaRef.current;
    //   if (media) {
    //     media.oncanplay = () => {
    //       setIsLoading(false);
    //     };
    //   }
    //   return () => {
    //     if (media) media.oncanplay = null;
    //   };
    // }, [mediaRef]);

    useEffect(() => {
      // let job: any = null;
      const media = mediaRef.current;
      if (!media) return;

      // if (isLoadMedia && media) {
      //   let timeRetry = 0;
      //   // media.preload = 'auto';

      //   job = setInterval(() => {
      //     if (timeRetry >= TIME_TO_RETRY_ATTEMP_RELOAD_MEDIA) {
      //       clearInterval(job);
      //       handleMediaCanPlay(index, true);
      //     }

      //     media.onerror = function() {
      //       clearInterval(job);
      //       handleMediaCanPlay(index, true);
      //     };

      //     media.oncanplay = function() {
      //       clearInterval(job);
      //       setIsLoading(false);
      //       handleMediaCanPlay(index, false);
      //     };

      //     media!.preload = 'auto';
      //     setUrlMedia(url);
      //     timeRetry += 500;
      //   }, 500);

      media.preload = 'auto';

      media.oncanplay = () => {
        setIsLoading(false);
      };

      media.onerror = function() {
        setIsLoading(false);
      };

      const source = media.querySelector('source');
      if (source) {
        source.onerror = function() {
          setIsLoading(false);
        };
      }

      // return () => {
      //   clearInterval(job);
      // };
    }, [mediaRef.current]);

    function handleReactOnEmoji(segment: Segment, type: ReactionType) {
      if (!segment?.reactions) return;

      const isReacted = segment.reactions.some((reaction: Reaction) => reaction.type === type);
      amendReaction({
        variables: {
          segmentFingerprint: segment.fingerprint,
          type,
          remove: isReacted,
        },
      });
    }

    const renderMedia = useMemo(() => {
      const type = segment?.imported_media?.type;

      if (type === 'video') {
        return (
          <React.Fragment>
            {isTimeOut && isLoading && <span style={{ paddingLeft: 12 }}>time out</span>}
            {isLoading && (
              <span>
                <FontAwesomeIcon icon={faSpinner} className="fa-spin video-load-indicator" width={16} height={16} />
              </span>
            )}
            <div className="segment-thumb">
              <video
                preload="none"
                ref={mediaRef}
                onClick={() => handleVideoThumbnailClicked()}
                title="Click to preview your edit from this point"
              >
                <source key={urlMedia} src={urlMedia} type="video/mp4" />
              </video>
            </div>
          </React.Fragment>
        );
      }

      return (
        <React.Fragment>
          {isTimeOut && <span style={{ paddingLeft: 12 }}>time out</span>}
          {isLoading && (
            <span>
              <FontAwesomeIcon icon={faSpinner} className="fa-spin video-load-indicator" width={16} height={16} />
            </span>
          )}
          <div onClick={() => handleVideoThumbnailClicked()} className="segment-thumb">
            <video
              style={{ opacity: 0, width: 0 }}
              preload="none"
              ref={mediaRef}
              title="Click to preview your edit from this point"
            >
              <source key={urlMedia} src={urlMedia} type="audio/mp3" />
            </video>
            <img src="https://app.simonsaysai.com/img/poster-audio.png" style={{ opacity: !isLoading ? 1 : 0 }} />
          </div>
        </React.Fragment>
      );
    }, [segment.imported_media, isLoading, urlMedia, handleVideoThumbnailClicked]);

    const hasAnnotations = isOpenThread || segment.annotations?.length;
    const reactions = useMemo(() => {
      const mapReactions = new Map();
      segment.reactions?.map(seg => {
        const { type } = seg;
        if (mapReactions.has(type)) {
          mapReactions.set(type, formatCountReaction(mapReactions.get(type) + 1));
        } else {
          mapReactions.set(type, formatCountReaction(1));
        }
      });

      return mapReactions;
    }, [segment.reactions]);

    function formatCountReaction(numOfReaction: number) {
      if (numOfReaction === 1) return '';
      if (numOfReaction >= 1000) return (numOfReaction * 0.001).toFixed(1) + 'k';
      return numOfReaction;
    }

    function togglePopoverDuration() {
      setIsOpenPopoverDuration(prev => !prev);
    }

    function handleKeydown(e: any) {
      if (e.key === 'Enter') {
        amendBlankSegment('duration');
        togglePopoverDuration();
      }
    }

    function amendBlankSegment(type: string) {
      if (inputBlankSegmentDuration.value === segment.end && inputBlankSegmentText.value === segment.text) return;

      const newSegment = {
        ...segment,
        ...(type === 'duration' ? { end: +inputBlankSegmentDuration.value.trim() || 0 } : {}),
        ...(type === 'text' ? { text: inputBlankSegmentText.value.trim() || 'Untitled Black Video' } : {}),
      };

      handleEditBlankSegment(index, newSegment);
    }

    if (segment.imported_media === null) {
      return (
        <React.Fragment>
          <div style={{ position: 'relative' }} ref={ref}>
            <div className="horizontal-line-card"></div>
            <div className={`horizontal-line-playing ${isCurrent ? 'playing' : ''}`}></div>
            <div style={{ display: 'none' }}>
              <video className="js-blank-screen" title="Click to preview your edit from this point">
                <source src={url} type="video/mp4" />
              </video>
            </div>

            <div className="blank-card" onClick={() => handleVideoThumbnailClicked()}>
              <div className="blank-card__text">
                <span className="icon-draggable">
                  <FontAwesomeIcon icon={faGripVertical} width={14} height={14} />
                </span>
                <span>
                  <ChapterItemSVG />
                </span>
                <input
                  placeholder='e.g. "B-roll of NYC skyline"'
                  value={inputBlankSegmentText.value}
                  onChange={inputBlankSegmentText.onChange}
                  onClick={e => e.stopPropagation()}
                  onKeyDown={e => e.key === 'Enter' && amendBlankSegment('text')}
                  onBlur={() => amendBlankSegment('text')}
                />
              </div>
              <div
                className="blank-card__duration"
                id={'popover-duration-' + segment.fingerprint}
                onClick={e => {
                  e.stopPropagation();
                  togglePopoverDuration();
                }}
              >
                <span>{diffTime(segment.start, segment.end)} </span>
                <span style={{ height: 18, paddingLeft: 2 }}>
                  <FontAwesomeIcon icon={faHistory} width={14} height={16} />
                </span>
              </div>
              <div className="trash-icon">
                {!readOnly && (
                  <Button
                    close
                    onClick={e => {
                      e.stopPropagation();
                      handleRemoveClicked();
                    }}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} width={14} height={16} />
                  </Button>
                )}
              </div>
            </div>
          </div>
          <Popover
            target={'popover-duration-' + segment.fingerprint}
            placement="right"
            isOpen={isOpenPopoverDuration}
            toggle={togglePopoverDuration}
            modifiers={popoverDurationModifiers() as Popper.Modifiers}
          >
            <div className="popover-duration">
              <input
                autoFocus
                type="text"
                placeholder="Enter duration"
                value={inputBlankSegmentDuration.value}
                onChange={inputBlankSegmentDuration.onChange}
                onBlur={() => {
                  togglePopoverDuration();
                  inputBlankSegmentDuration.setvalue(segment.end);
                }}
                onKeyDown={handleKeydown}
              />
            </div>
          </Popover>
        </React.Fragment>
      );
    }

    return (
      <div ref={ref}>
        <div className="horizontal-line-card"></div>
        <div className={`horizontal-line-playing ${isCurrent ? 'playing' : ''}`}></div>
        <Card>
          <Row noGutters>
            <div>{renderMedia}</div>
            <div style={{ flex: 1 }}>
              <CardBody>
                <React.Fragment>
                  <span className="card-tools">
                    <span className="reactions">
                      <span
                        className={`reactions__item ${reactions.has(ReactionEnum.LIKE) ? 'active' : ''}`}
                        onClick={() => handleReactOnEmoji(segment, ReactionEnum.LIKE)}
                        onMouseDown={e => e.stopPropagation()}
                      >
                        <span className="reaction__icon">
                          <span>👍</span>
                        </span>
                        <span className="reaction__text">{reactions.get(ReactionEnum.LIKE)}</span>
                      </span>
                      <span
                        className={`reactions__item ${reactions.has(ReactionEnum.DISLIKE) ? 'active' : ''}`}
                        onClick={() => handleReactOnEmoji(segment, ReactionEnum.DISLIKE)}
                        onMouseDown={e => e.stopPropagation()}
                      >
                        <span className="reaction__icon">
                          <span>👎</span>
                        </span>
                        <span className="reaction__text">{reactions.get(ReactionEnum.DISLIKE)}</span>
                      </span>
                      <span
                        className={`reactions__item ${reactions.has(ReactionEnum.LOVE) ? 'active' : ''}`}
                        onClick={() => handleReactOnEmoji(segment, ReactionEnum.LOVE)}
                        onMouseDown={e => e.stopPropagation()}
                      >
                        <span className="reaction__icon">
                          <span>❤️</span>
                        </span>
                        <span className="reaction__text">{reactions.get(ReactionEnum.LOVE)}</span>
                      </span>
                      <span
                        className={`reactions__item ${reactions.has(ReactionEnum.LAUGH) ? 'active' : ''}`}
                        onClick={() => handleReactOnEmoji(segment, ReactionEnum.LAUGH)}
                        onMouseDown={e => e.stopPropagation()}
                      >
                        <span className="reaction__icon">
                          <span>😂</span>
                        </span>
                        <span className="reaction__text">{reactions.get(ReactionEnum.LAUGH)}</span>
                      </span>
                    </span>
                    <span className={`annotation-icon ${hasAnnotations ? 'active' : 'inactive'}`}>
                      <Button close>
                        <Thread
                          hasAnnotations={!!segment.annotations?.length}
                          isOpen={isOpenThread}
                          toggle={() => setIsOpenThread(prevState => !prevState)}
                          forwardKey={segment.fingerprint}
                        >
                          {isOpenThread && (
                            <ThreadPopover
                              annotations={segment.annotations || []}
                              segmentFingerprint={segment.fingerprint}
                              toggle={() => setIsOpenThread(prevState => !prevState)}
                            />
                          )}
                        </Thread>
                      </Button>
                    </span>
                  </span>
                </React.Fragment>
                <CardTitle>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ cursor: 'pointer' }} onClick={() => handleSegmentClicked(segment)}>
                      <span className="duration">
                        {diffTime(segment.start, segment.end)} |{' '}
                        {formatTime(segment.imported_media, String(segment.start), String(startTimecodeInSeconds))}-
                        {formatTime(segment.imported_media, String(segment.end), String(startTimecodeInSeconds))}
                      </span>
                      <span className="filename"> | {segment?.imported_media?.name}</span>
                    </div>
                  </div>
                </CardTitle>
                <CardText>
                  {segment.speaker?.name && (
                    <span
                      style={{ color: 'rgb(200, 36, 127)', fontWeight: 'bold', fontSize: 12 }}
                    >{`${segment.speaker?.name}: `}</span>
                  )}
                  {segment.text}
                </CardText>
                <React.Fragment>
                  <span className={`card-tools`} style={{ float: 'right', display: 'flex', alignItems: 'center' }}>
                    <span className="trash-icon inactive" style={{ padding: '4px 0' }}>
                      {!readOnly && (
                        <Button
                          close
                          onClick={e => {
                            e.stopPropagation();
                            handleRemoveClicked();
                          }}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} width={14} height={16} />
                        </Button>
                      )}
                    </span>
                  </span>
                </React.Fragment>
              </CardBody>
            </div>
          </Row>
        </Card>
      </div>
    );
  },
);

function areEqual(prevProps: SegmentCardProps, nextProps: SegmentCardProps) {
  // if (prevProps.isLoadMedia !== nextProps.isLoadMedia) return false;
  if (!nextProps.canView) return true;

  return false;
}
export default React.memo(SegmentCard, areEqual);
